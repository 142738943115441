import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../components/LoadingSpinner";
import axios from "axios";
import { VIEWS } from "../utils/Views";
import TopNavigation from "../components/TopNavigation";
import Footer from "../components/Footer";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { useScrollToTop } from "../hooks/useScrollToTop";

export default function Article() {
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const blogID = searchParams.get("id");
  const [article, setArticle] = useState({}) as any;
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  useEffect(() => {
    getBlogArticle();
  }, []);

  useDocumentTitle("Blog | Dr. Sugi Mental Health");
  useScrollToTop();
  
  const getBlogArticle = async () => {
    setLoadingSpinner(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_BACKEND}api/blogs/${blogID}`
      );
      setArticle(response.data);
      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
      toast.error(error.message);
    }
  };

  return (
    <React.Fragment>
      <TopNavigation activeItem={""} />
      <div className="mt-24">
        <div className="w-full pl-8 pr-8 lg:pl-48 lg:pr-48">
          <h1 
            className="text-left text-4xl md:text-6xl md:leading-none font-bold uppercase mt-36 mb-16">{article.title}</h1>
          <div
                className="flex justify-center items-center h-96 w-full rounded-xl drop-shadow-lg mb-12"
                style={{
                  backgroundImage: "url(" + article.cover + ")",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
                // data-aos="fade"
              ></div>
          <p className="text-justify mb-16 lg:mb-48" style={{whiteSpace: "pre-wrap"}}>{article.content}</p>
        </div>
        <Footer />
      </div>
      {loadingSpinner ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
