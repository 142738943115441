import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingSpinner from "../components/LoadingSpinner";
import PopUpFullSizeImage from "./PopUpFullSizeImage";

export default function ImageGallerySection() {
  const [allImagesData, setAllImagesData] = useState([]) as any[];
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [fullSizeImagePopUp, setFullSizeImagePopUp] = useState({imageURL : '', open:false});

  useEffect(() => {
    getAllImages();
  }, []);

  const getAllImages = async () => {
    setLoadingSpinner(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_BACKEND}api/images`
      );
      console.log(response.data);
      setAllImagesData(response.data || []);
      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
      console.log(error);
    }
  };

  const viewFullsizeImage = (imageUrl:string) => {
    console.log(imageUrl);
    setFullSizeImagePopUp({
      imageURL : imageUrl, 
      open:true
    })
  }

  return (
    <React.Fragment>
      <div className="w-full p-8 mb-5 md:p-10 md:mb-10 lg:p-20 lg:mb-20">
        <h1 className="text-5xl text-center md:text-left md:text-7xl font-bold uppercase">Gallery</h1>
        {allImagesData.length === 0 ? (
            <p className="text-xl lg:text-4xl text-gray-400 text-center mt-20">
              {/* Gallery images are loading... */}
            </p>
          ) : null}
        <div className="grid grid-col-1 gap-4 md:grid-cols-3 lg:grid-cols-4 place-items-center h-fit mt-14">
          {allImagesData.map((item: any, index: any) => (
            <div className="flex flex-col justify-center items-center bg-indigo-100 hover:bg-indigo-200 w-full h-48 md:h-64 rounded-2xl p-5 shaddow-xl cursor-pointer animate__animated animate__slow animate__zoomIn" key={index.toString()} 
            // data-aos="zoom-in" 
            style={{
              backgroundImage: "url(" + item.imageString + ")",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            onClick={() => viewFullsizeImage(item.imageString)}
            >
            </div>
          )).reverse()}
        </div>
      </div>
      {loadingSpinner ? <LoadingSpinner /> : null}
      <PopUpFullSizeImage
        fullSizeImageDisplay={fullSizeImagePopUp.open}
        handleClose={() => setFullSizeImagePopUp({ ...fullSizeImagePopUp, open: false })}
        handleOpen={() => setFullSizeImagePopUp({ ...fullSizeImagePopUp, open: true })}
        imageUrl={fullSizeImagePopUp.imageURL}
      />
    </React.Fragment>
  );
}
