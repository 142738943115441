import React from "react";
import { Link } from "react-router-dom";
import aboutMeImg from "../assets/images/aboutme.jpg";
import { VIEWS } from "../utils/Views";

export default function AboutSection() {
  return (
    <React.Fragment>
      <div className="w-full p-5 lg:pl-20 lg:pr-20 lg:pt-10 mb-10">
        <div className="grid grid-col-1 gap-0 md:grid-cols-2 place-items-center h-fit mt-10">
          <div className="w-full bg-indigo-100 h-full p-10 md:p-15 lg:p-20">
            <h1 className="text-4xl text-left md:text-7xl font-bold uppercase">
              About Me
            </h1>
            <h1 className="text-xl font-bold mt-2">Dr. Sugi Subawickrama</h1>
            <p className="text-base font-medium text-left mt-5 md:mt-10 mb-10 text-slate-600">
              Dr. Sugi (Sugandika T Subawickrama) a Licensed Psychotherapist
              (DSL, LMHC (N.Y.), LPC (N.J.), Master CASAC, CLSSBB). Member of
              American Psychological Association, American Counseling
              Association &amp; Honor societies: Sigma Beta Delta and Omega Nu
              Lambda.TEDx, Motivational, Leadership Speaker (National &amp;
              International), Researcher, and an Influencer.
            </p>
            <Link
              className="bg-indigo-700 hover:bg-indigo-900 text-white font-bold py-2 px-4 rounded"
              to={VIEWS.ABOUT}
            >
              Read More
            </Link>
          </div>
          <div className="flex justify-center items-center w-full bg-indigo-700 h-full">
            <img
              src={aboutMeImg}
              alt=""
              className="w-3/5 h-auto"
              // data-aos="fade"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
