import React, { useEffect, useState } from "react";
import { VIEWS } from "../utils/Views";
import { Link } from "react-router-dom";

export default function TopNavigation(props: { activeItem: string }) {
  const [displyMobileMenu, setdisplyMobileMenu] = useState(false);

  return (
    <React.Fragment>
      <div className="w-full p-10 bg-opacity-80 bg-indigo-900 h-24 flex flex-row justify-between items-center shadow-xl fixed top-0 left-0 backdrop-blur-lg z-50">
        <div className="logo">
          <h1 className="text-white text-lg lg:text-2xl font-bold cursor-pointer">
            <Link to={VIEWS.HOME}>Dr. Sugi Mental Health</Link>
          </h1>
        </div>
        <div className="menu-items hidden md:block">
          <ul className="md:flex md:item-center">
            <li className="ml-5 md:ml-10 text-md lg:text-lg">
              {props.activeItem == "HOME" ? (
                <Link
                  className="text-white hover:text-indigo-400 font-semibold duration-500 border-b-4 border-indigo-400"
                  to={VIEWS.HOME}
                >
                  Home
                </Link>
              ) : (
                <Link
                  className="text-white hover:text-indigo-400 font-semibold duration-500"
                  to={VIEWS.HOME}
                >
                  Home
                </Link>
              )}
            </li>
            <li className="ml-5 md:ml-10 text-md lg:text-lg">
              {props.activeItem == "ABOUT" ? (
                <Link
                  className="text-white hover:text-indigo-400 font-semibold duration-500 border-b-4 border-indigo-400"
                  to={VIEWS.ABOUT}
                >
                  About Me
                </Link>
              ) : (
                <Link
                  className="text-white hover:text-indigo-400 font-semibold duration-500"
                  to={VIEWS.ABOUT}
                >
                  About Me
                </Link>
              )}
            </li>
            <li className="ml-5 md:ml-10 text-md lg:text-lg">
              {props.activeItem == "GALLERY" ? (
                <Link
                  className="text-white hover:text-indigo-400 font-semibold duration-500 border-b-4 border-indigo-400"
                  to={VIEWS.GALLERY}
                >
                  Gallery
                </Link>
              ) : (
                <Link
                  className="text-white hover:text-indigo-400 font-semibold duration-500"
                  to={VIEWS.GALLERY}
                >
                  Gallery
                </Link>
              )}
            </li>
            <li className="ml-5 md:ml-10 text-md lg:text-lg">
              {props.activeItem == "BOOKING" ? (
                <Link
                  className="text-white hover:text-indigo-400 font-semibold duration-500 border-b-4 border-indigo-400"
                  to={VIEWS.BOOKING}
                >
                  Booking
                </Link>
              ) : (
                <Link
                  className="text-white hover:text-indigo-400 font-semibold duration-500"
                  to={VIEWS.BOOKING}
                >
                  Booking
                </Link>
              )}
            </li>
            <li className="ml-5 md:ml-10 text-md lg:text-lg">
              {props.activeItem == "BLOG" ? (
                <Link
                  className="text-white hover:text-indigo-400 font-semibold duration-500 border-b-4 border-indigo-400"
                  to={VIEWS.BLOG}
                >
                  Blog
                </Link>
              ) : (
                <Link
                  className="text-white hover:text-indigo-400 font-semibold duration-500"
                  to={VIEWS.BLOG}
                >
                  Blog
                </Link>
              )}
            </li>
            <li className="ml-5 md:ml-10 text-md lg:text-lg">
              {props.activeItem == "CONTACT" ? (
                <Link
                  className="text-white hover:text-indigo-400 font-semibold duration-500 border-b-4 border-indigo-400"
                  to={VIEWS.CONTACT}
                >
                  Contact
                </Link>
              ) : (
                <Link
                  className="text-white hover:text-indigo-400 font-semibold duration-500"
                  to={VIEWS.CONTACT}
                >
                  Contact
                </Link>
              )}
            </li>
          </ul>
        </div>
        {displyMobileMenu ? (
          <div className="md:hidden">
            <button
              className="text-white text-3xl"
              onClick={() => setdisplyMobileMenu(!displyMobileMenu)}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
        ) : (
          <div className="md:hidden">
            <button
              className="text-white text-3xl"
              onClick={() => setdisplyMobileMenu(!displyMobileMenu)}
            >
              <i className="fa-solid fa-bars"></i>
            </button>
          </div>
        )}
      </div>
      {displyMobileMenu ? (
        <div className="w-full h-screen bg-indigo-900 z-50 top-0 left-0 md:hidden mt-20 fixed flex justify-center items-start">
          <ul className="text-center">
            <li className="mt-8 mb-8 text-4xl">
              <Link
                className="text-white hover:text-indigo-400 font-semibold duration-500"
                to={VIEWS.HOME}
              >
                Home
              </Link>
            </li>
            <li className="mt-8 mb-8 text-4xl">
              <Link
                className="text-white hover:text-indigo-400 font-semibold duration-500"
                to={VIEWS.ABOUT}
              >
                About
              </Link>
            </li>
            <li className="mt-8 mb-8 text-4xl">
              <Link
                className="text-white hover:text-indigo-400 font-semibold duration-500"
                to={VIEWS.GALLERY}
              >
                Gallery
              </Link>
            </li>
            <li className="mt-8 mb-8 text-4xl">
              <Link
                className="text-white hover:text-indigo-400 font-semibold duration-500"
                to={VIEWS.BOOKING}
              >
                Booking
              </Link>
            </li>
            <li className="mt-8 mb-8 text-4xl">
              <Link
                className="text-white hover:text-indigo-400 font-semibold duration-500"
                to={VIEWS.BLOG}
              >
                Blog
              </Link>
            </li>
            <li className="mt-8 mb-8 text-4xl">
              <Link
                className="text-white hover:text-indigo-400 font-semibold duration-500"
                to={VIEWS.CONTACT}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      ) : null}
    </React.Fragment>
  );
}
