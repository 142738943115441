import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminBlogs from "../../components/admin/AdminBlogs";
import AdminBookings from "../../components/admin/AdminBookings";
import AdminImages from "../../components/admin/AdminImages";
import AdminMessages from "../../components/admin/AdminMessages";
import AdminSettings from "../../components/admin/AdminSettings";
import AdminTopNavBar from "../../components/admin/AdminTopNavBar";
import AdminVideos from "../../components/admin/AdminVideos";
import SideNavBarItem from "../../components/admin/SideNavBarItem";
import LoadingSpinner from "../../components/LoadingSpinner";
import PopUp from "../../components/PopUp";
import proPic from '../../assets/images/cropped_propic.png'
import { VIEWS } from "../../utils/Views";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useScrollToTop } from "../../hooks/useScrollToTop";

export default function Dashboard() {
  const navigate = useNavigate();

  const [popUpConfirm, setPopUpConfirm] = useState({ open: false, title: '', content: '' });
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const [isActiveItem1, setIsActiveItem1] = useState(true);
  const [displayAllMessages, setDisplayAllMessages] = useState(true);

  const [isActiveItem2, setIsActiveItem2] = useState(false);
  const [displayAllBookings, setDisplayAllBookings] = useState(false);

  const [isActiveItem3, setIsActiveItem3] = useState(false);
  const [displayAllBlogs, setDisplayAllBlogs] = useState(false);

  const [isActiveItem4, setIsActiveItem4] = useState(false);
  const [displayAddImages, setDisplayAddImages] = useState(false);

  const [isActiveItem5, setIsActiveItem5] = useState(false);
  const [displayAddVideos, setDisplayAddVideos] = useState(false);

  const [isActiveItem6, setIsActiveItem6] = useState(false);
  const [displaySettings, setDisplaySettings] = useState(false);

  useEffect(() => {
    setDisplayAllMessages(true);
    setIsActiveItem1(true);
  }, []);

  useDocumentTitle("Dashboard | Dr. Sugi Mental Health");
  useScrollToTop();

  function showMessages() {
    setDisplayAllMessages(true);
    setDisplayAllBookings(false);
    setDisplayAllBlogs(false);
    setDisplayAddImages(false);
    setDisplayAddVideos(false);
    setDisplaySettings(false);
    setIsActiveItem1(true);
    setIsActiveItem2(false);
    setIsActiveItem3(false);
    setIsActiveItem4(false);
    setIsActiveItem5(false);
    setIsActiveItem6(false);
  }

  function showBookings() {
    setDisplayAllMessages(false);
    setDisplayAllBookings(true);
    setDisplayAllBlogs(false);
    setDisplayAddImages(false);
    setDisplayAddVideos(false);
    setDisplaySettings(false);
    setIsActiveItem1(false);
    setIsActiveItem2(true);
    setIsActiveItem3(false);
    setIsActiveItem4(false);
    setIsActiveItem5(false);
    setIsActiveItem6(false);
  }

  function showBlogs() {
    setDisplayAllMessages(false);
    setDisplayAllBookings(false);
    setDisplayAllBlogs(true);
    setDisplayAddImages(false);
    setDisplayAddVideos(false);
    setDisplaySettings(false);
    setIsActiveItem1(false);
    setIsActiveItem2(false);
    setIsActiveItem3(true);
    setIsActiveItem4(false);
    setIsActiveItem5(false);
    setIsActiveItem6(false);
  }

  function showImages() {
    setDisplayAllMessages(false);
    setDisplayAllBookings(false);
    setDisplayAllBlogs(false);
    setDisplayAddImages(true);
    setDisplayAddVideos(false);
    setDisplaySettings(false);
    setIsActiveItem1(false);
    setIsActiveItem2(false);
    setIsActiveItem3(false);
    setIsActiveItem4(true);
    setIsActiveItem5(false);
    setIsActiveItem6(false);
  }

  function showVideos() {
    setDisplayAllMessages(false);
    setDisplayAllBookings(false);
    setDisplayAllBlogs(false);
    setDisplayAddImages(false);
    setDisplayAddVideos(true);
    setDisplaySettings(false);
    setIsActiveItem1(false);
    setIsActiveItem2(false);
    setIsActiveItem3(false);
    setIsActiveItem4(false);
    setIsActiveItem5(true);
    setIsActiveItem6(false);
  }

  function showSettings() {
    setDisplayAllMessages(false);
    setDisplayAllBookings(false);
    setDisplayAllBlogs(false);
    setDisplayAddImages(false);
    setDisplayAddVideos(false);
    setDisplaySettings(true);
    setIsActiveItem1(false);
    setIsActiveItem2(false);
    setIsActiveItem3(false);
    setIsActiveItem4(false);
    setIsActiveItem5(false);
    setIsActiveItem6(true);
  }

  function signOut() {
    navigate(VIEWS.ADMINSIGNIN);
    sessionStorage.clear();
  }

  function handlePopUpConfirm(){
    alert("Popup Confirmed");
  }


  return (
    <React.Fragment>
      <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white">
        <AdminTopNavBar />
        <div className="fixed flex flex-col top-20 left-0 w-14 hover:w-64 md:w-64 bg-gray-900 h-full text-white transition-all duration-300 border-none z-10 sidebar">
          <div className="hidden md:block">
            <p className="flex flex-col justify-center items-center text-white font-semibold text-center h-fit mt-10">
              <img src={proPic} alt="" className="w-20 h-auto"/>
              Welcome, Sugi
            </p>
          </div>
          <div className="overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow">
            <ul className="flex flex-col py-4 space-y-1">
              <SideNavBarItem
                iconClass={"fa-solid fa-message"}
                linkText={"Messages"}
                action={showMessages}
                isActiveItem={isActiveItem1}
              />
              <SideNavBarItem
                iconClass={"fa-solid fa-ticket"}
                linkText={"Bookings"}
                action={showBookings}
                isActiveItem={isActiveItem2}
              />
              <SideNavBarItem
                iconClass={"fa-solid fa-blog"}
                linkText={"Blogs"}
                action={showBlogs}
                isActiveItem={isActiveItem3}
              />
              <SideNavBarItem
                iconClass={"fa-solid fa-image"}
                linkText={"Images"}
                action={showImages}
                isActiveItem={isActiveItem4}
              />
              <SideNavBarItem
                iconClass={"fa-solid fa-video"}
                linkText={"Videos"}
                action={showVideos}
                isActiveItem={isActiveItem5}
              />
              <SideNavBarItem
                iconClass={"fa-solid fa-gear"}
                linkText={"Settings"}
                action={showSettings}
                isActiveItem={isActiveItem6}
              />
              <SideNavBarItem
                iconClass={"fa-solid fa-right-from-bracket"}
                linkText={"Sign Out"}
                action={signOut}
              />
            </ul>
          </div>
        </div>
        <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
          {displayAllMessages ? <AdminMessages /> : null}
          {displayAllBookings ? <AdminBookings /> : null}
          {displayAllBlogs ? <AdminBlogs /> : null}
          {displayAddImages ? <AdminImages /> : null}
          {displayAddVideos ? <AdminVideos /> : null}
          {displaySettings ? <AdminSettings /> : null}
        </div>
      </div>
      <ToastContainer />
      <PopUp 
        popUpDisplay={popUpConfirm.open} 
        handleClose={() => setPopUpConfirm({ ...popUpConfirm, open: false })}
        handleOpen={() => setPopUpConfirm({ ...popUpConfirm, open: true })}
        popUpContent={popUpConfirm.content} 
        handleConfirm={handlePopUpConfirm} 
      />
      {loadingSpinner ? <LoadingSpinner /> : null}
    </React.Fragment>
  );
}
