import React from 'react'
import headerPropic from '../assets/images/cropped_quality.png'
import headerBg from '../assets/images/bg.jpg'
import { Link } from 'react-router-dom'
import { VIEWS } from '../utils/Views'

export default function Header() {
  return (
    <React.Fragment>
        <div className="w-full p-5 bg-indigo-600 h-fit lg:h-screen grid grid-cols-1 md:grid-cols-2 gap-4 shadow-lg"
            style={{
                backgroundImage: "url(https://conference.almamater.si/wp-content/uploads/2022/09/conference_OG_1.jpg)",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
        >
            <div className='w-full h-full bg-black bg-opacity-50 top-16 lg:top-24 left-0 absolute'></div>

            <div className='flex flex-col align-middle mt-5 md:ml-10 lg:mt-36 lg:ml-20 z-10'>
                <h1 className='text-8xl text-white font-bold uppercase text-left animate__animated animate__fadeInLeft animate__slow'>Dr.Sugi</h1>
                <h1 className='text-4xl lg:text-5xl text-white font-bold uppercase animate__animated animate__fadeInRight animate__slow'>Subawickrama</h1>
                <p className='text-sm lg:text-xl text-white font-bold uppercase mt-4 lg:mt-10  animate__animated animate__fadeIn animate__delay-2s'>mental health | public speaking | motivation</p>
                <div className='flex flex-row animate__animated animate__fadeIn animate__delay-2s'>
                    <Link to={VIEWS.BOOKING} className="bg-indigo-700 hover:bg-indigo-500 text-white font-bold py-3 px-4 lg:py-4 lg:px-6 rounded w-fit uppercase mt-5 text-center">
                        Book a Speech
                    </Link>
                    <Link to={VIEWS.CONTACT} className="bg-slate-700 hover:bg-slate-500 text-white font-bold py-3 px-4 lg:py-4 lg:px-6 rounded w-fit uppercase mt-5 ml-4 text-center">
                        Contact me
                    </Link>
                </div>
            </div>
            <div className='flex flex-col justify-center items-center z-10 animate__animated animate__zoomIn animate__slow'>
                <img className='w-64 h-64 mt-6 mb-12 lg:w-96 lg:h-96 lg:-mt-20 lg:mb-0' src={headerPropic} alt=''/>
            </div>
        </div>
    </React.Fragment>
  )
}
