import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import VideoGalleryEnglish from "./VideoGalleryEnglish";
import VideoGallerySinhala from "./VideoGallerySinhala";

export default function VideoGallerySection() {
  const [engVideoSection, setEngVideoSection] = useState(true);
  let activeBtn:string = "bg-indigo-700 text-white font-bold py-3 px-4 lg:py-4 lg:px-6 rounded w-fit uppercase mt-5 text-center mx-1 underline underline-offset-4";
  let hoverBtn:string = "bg-slate-400 hover:bg-slate-500 text-white font-bold py-3 px-4 lg:py-4 lg:px-6 rounded w-fit uppercase mt-5 text-center mx-1"; 

  return (
    <React.Fragment>
      <div className="w-full p-8 md:p-10 lg:p-20 bg-indigo-100">
        <h1 className="text-5xl text-center md:text-left md:text-7xl font-bold uppercase">
          Featured Videos
        </h1>
        <div className="flex flex-row w-full items-center">
          <button
            className={engVideoSection ? activeBtn : hoverBtn}
            type="button"
            onClick={() => setEngVideoSection(!engVideoSection)}
          >
            English Videos
          </button>
          <button
            className={engVideoSection ? hoverBtn : activeBtn}
            type="button"
            onClick={() => setEngVideoSection(!engVideoSection)}
          >
            Sinhala Videos
          </button>
        </div>
        {engVideoSection ? <VideoGalleryEnglish /> : <VideoGallerySinhala />}
      </div>
    </React.Fragment>
  );
}
