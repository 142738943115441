import React from "react";
import BlogSection from "../components/BlogSection";
import Footer from "../components/Footer";
import TopNavigation from "../components/TopNavigation";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { useScrollToTop } from "../hooks/useScrollToTop";

export default function Blog() {
  useDocumentTitle("Blog | Dr. Sugi Mental Health");
  useScrollToTop();
  return (
    <React.Fragment>
      <TopNavigation activeItem={"BLOG"} />
      <div className="mt-24">
        <BlogSection />
        <Footer />
      </div>
    </React.Fragment>
  );
}
