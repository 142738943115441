import React from "react";
import { Link } from "react-router-dom";
import TopNavigation from "../components/TopNavigation";
import { VIEWS } from "../utils/Views";
import aboutMeImg from "../assets/images/aboutme.jpg";
import headerPropic from "../assets/images/cropped_quality.png";
import Footer from "../components/Footer";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { useScrollToTop } from "../hooks/useScrollToTop";

export default function About() {
  useDocumentTitle("About Me | Dr. Sugi Mental Health");
  useScrollToTop();
  return (
    <React.Fragment>
      <TopNavigation activeItem={"ABOUT"} />
      <div className="mt-24">
        <div className="w-full p-0 mt-24 lg:mt-0 lg:pl-14 lg:pr-14 lg:pt-5 lg:mb-10 animate__animated animate__slow animate__fadeIn">
          <div className="grid grid-col-1 place-items-center h-fit mt-10">
            <div className="w-full bg-indigo-100 h-full p-8 md:p-15 lg:p-20">
              <div className="flex flex-col lg:flex-row items-center">
                <img
                  className="mb-5 lg:mb-0 w-48 h-48 lg:-mt-10"
                  src={headerPropic}
                  alt=""
                />
                <div className="lg:ml-5">
                  <h1 className="text-center lg:text-left text-5xl md:text-7xl font-bold uppercase">
                    Hi there...
                  </h1>
                  <h1 className="text-center lg:text-left text-2xl font-bold mt-0 lg:mt-2">
                    I'm Dr. Sugi Subawickrama
                  </h1>
                </div>
              </div>
              <p className="text-base font-medium text-left mt-10 mb-10 text-slate-600">
                Dr. Sugi (Sugandika T Subawickrama) a Licensed Psychotherapist
                (DSL, LMHC (N.Y.), LPC (N.J.), Master CASAC, CLSSBB). Member of
                American Psychological Association, American Counseling
                Association &amp; Honor societies: Sigma Beta Delta and Omega Nu
                Lambda.TEDx, Motivational, Leadership Speaker (National &amp;
                International), Researcher, and an Influencer.
                <br />
                <br />
                Dr. Sugi (Sugandika T Subawickrama) is originally from Sri
                Lanka. She is a licensed psychotherapist in New York (LMHC) and
                New Jersey (LPC), a certified substance abuse clinician (Master
                CASAC) in New York and internationally and is also an approved
                DWI assessor and clinical screener for impaired driving
                offenders in New York. She is a strategic leader and a
                motivational speaker as well. Dr. Sugi has worked in Behavioral
                Health, Mental Health, quality, research, and Substance Abuse
                Counseling for over 20 years in various treatment settings. Her
                educational journey in Sri Lanka, India, the United Kingdom, and
                the United States helped her to learn coping strategies and
                leadership skills. She completed the &quot;Science of
                Well-being&quot; course at Yale University, which provided a
                different perspective on life and happiness. Dr. Sugi completed
                her Doctorate from Liberty University, with a 4.0 GPA, and is
                affiliated with Sigma Beta Delta and Omega Nu Lambda Honor
                Societies. In addition, she is a Six Sigma Black Belt and a
                member of the American Counseling Association. She also serves
                as a chairwoman for youth at the VFW (Veterans of Foreign Wars).
                She has been selected/accepted to the roster for the Fulbright
                Specialist Program, allowing her to represent the USA and use
                her knowledge and experience with the world. <br />
                <br />
                Dr. Sugi is a volunteer TED translator/reviewer/language
                supervisor and has two YouTube channels in Sinhala and English
                on Mental Health and empowering individuals. Also, International
                Translation Day TED Translation giveaway winner. TEDWomen 2022
                subtitling contest winner (English to Sinhala: Get out of the
                glass box) and received an invitation to TEDWomen present to
                participate connector pass 2022. Dr. Sugi has delivered 2 TEDx
                talks so far, Immigrant Resilience (Southlake, Texas), and I Am
                Not My Past and Rising Above Trauma (Staten Island, New York).{" "}
                <br />
                <br />
                Dr. Sugi is passionate about research and is involved in
                numerous qualitative, quantitative, social, and marketing
                research both nationally and internationally. Dr. Sugi&#39;s
                passion is to use her skills to help individuals recover and
                rebuild their lives from the devastation caused by traumatic
                events, such as war, natural disasters (Tsunami, Hurricane),
                recurring substance use, and maladaptive coping skills. She has
                extensive experience providing individual and group counseling,
                while utilizing evidence- based approaches to help adults and
                children reach their treatment goals.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
