import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../LoadingSpinner";
import axios from "axios";
import PopUp from "../PopUp";
import AdminBlogsNewArticle from "./AdminBlogsNewArticle";
import AdminBlogsEditArticle from "./AdminBlogsEditArticle";

export default function AdminBlogs() {
  const [allBlogs, setAllBlogs] = useState([]) as any[];
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [authToken, setAuthToken] = useState("") as any;
  const [popUpConfirm, setPopUpConfirm] = useState({
    open: false,
    title: "",
    content: "",
    itemID: "",
  });
  const [allArticleSection, setAllArticleSection] = useState(false);
  const [newArticleSection, setNewArticleSection] = useState(false);
  const [editArticleSection, setEditArticleSection] = useState(false);
  const [editBlogArticleID, setEditBlogArticleID] = useState("");

  useEffect(() => {
    setAllArticleSection(true);
    const currentUserToken: any = sessionStorage.getItem("authToken");
    setAuthToken(currentUserToken);
    getAllBlogs();
  }, []);

  const getAllBlogs = async () => {
    setLoadingSpinner(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_BACKEND}api/blogs`
      );
      console.log(response.data);
      setAllBlogs(response.data);
      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
      toast.error(error.message);
    }
    setLoadingSpinner(false);
  };

  const deleteBlogPopUP = (blogID: any) => {
    setPopUpConfirm({
      title: "Alert",
      content: "Are you sure want to delete this blog?",
      open: true,
      itemID: blogID,
    });
  };

  const deleteBlogConfirm = () => {
    deleteBlog(popUpConfirm.itemID);
    setPopUpConfirm({ ...popUpConfirm, open: false });
  };

  const deleteBlog = async (blogID: any) => {
    setLoadingSpinner(true);
    const config: any = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    try {
      await axios.delete(
        `${process.env.REACT_APP_LOCAL_BACKEND}api/blogs/${blogID}`,
        config
      );
      getAllBlogs();
      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
      toast.error(error.message);
    }
    setLoadingSpinner(false);
  };

  function editBlog(blogID: any) {
    setEditArticleSection(!editArticleSection);
  }

  return (
    <React.Fragment>
      {allArticleSection && (
        <div>
          <h1 className="mt-16 ml-5 text-4xl text-gray-900 font-bold">
            All Blog Articles
          </h1>
          <div>
            <h6 className="mt-10 ml-10 text-md text-gray-900 font-bold capitalize">
              Add new blog article
            </h6>
            <button
              className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-10 mt-5 mb-5"
              onClick={() => {
                setAllArticleSection(false);
                setEditArticleSection(false);
                setNewArticleSection(true);
              }}
            >
              <i className="fa-solid fa-plus"></i> New article
            </button>
          </div>
          <h6 className="mt-10 ml-10 text-md text-gray-900 font-bold capitalize">
            Blog articles you have added
          </h6>
          <div className="mx-4">
            <div className="w-full overflow-hidden rounded-lg shadow-xs mt-5">
              <div className="w-full overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b bg-gray-200 ">
                      <th className="px-4 py-3">Title</th>
                      <th className="px-4 py-3">Date</th>
                      <th className="px-4 py-3">Content</th>
                      <th className="px-4 py-3 w-1/4">Action</th>
                    </tr>
                    {allBlogs.length === 0 ? (
                      <tr className="text-xs tracking-wide text-gray-400 border-b bg-gray-100 text-center">
                        <th className="p-10" colSpan={4}>
                          You have no blogs.
                        </th>
                      </tr>
                    ) : null}
                  </thead>
                  <tbody className="bg-white divide-y">
                    {allBlogs.map((item: any, index: any) => (
                      <tr
                        className="bg-gray-100 hover:bg-gray-300 text-gray-700"
                        key={index.toString()}
                      >
                        <td className="px-4 py-3">
                          <div className="flex items-center text-sm">
                            <div>
                              <p className="font-semibold">{item.title}</p>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-sm">{item.date}</td>
                        <td className="px-4 py-3 text-sm">
                          {item.content.substring(0, 100) + "....."}
                        </td>
                        <td className="px-4 py-3 text-sm">
                          <button
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
                            type="button"
                            onClick={() => {
                              setEditBlogArticleID(item._id);
                              setAllArticleSection(false);
                              setEditArticleSection(true);
                              setNewArticleSection(false);
                            }}
                          >
                            Edit <i className="fa-solid fa-pen-to-square"></i>
                          </button>
                          <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
                            type="button"
                            onClick={(e: any) => {
                              deleteBlogPopUP(item._id);
                            }}
                          >
                            Delete <i className="fa-solid fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    )).reverse()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {newArticleSection && (
        <AdminBlogsNewArticle
          setAllArticleSection={setAllArticleSection}
          setNewArticleSection={setNewArticleSection}
          setEditArticleSection={setEditArticleSection}
          refreshBlogTable={getAllBlogs}
        />
      )}
      {editArticleSection && (
        <AdminBlogsEditArticle
          setAllArticleSection={setAllArticleSection}
          setNewArticleSection={setNewArticleSection}
          setEditArticleSection={setEditArticleSection}
          refreshBlogTable={getAllBlogs}
          editBlogArticleID={editBlogArticleID}
        />
      )}
      {loadingSpinner ? <LoadingSpinner /> : null}
      <ToastContainer />
      <PopUp
        popUpDisplay={popUpConfirm.open}
        handleClose={() => setPopUpConfirm({ ...popUpConfirm, open: false })}
        handleOpen={() => setPopUpConfirm({ ...popUpConfirm, open: true })}
        popUpContent={popUpConfirm.content}
        handleConfirm={deleteBlogConfirm}
      />
    </React.Fragment>
  );
}
