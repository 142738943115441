import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../LoadingSpinner";
import axios from "axios";

export default function AdminBlogsNewArticle(props: {
  setAllArticleSection: any;
  setNewArticleSection: any;
  setEditArticleSection: any;
  refreshBlogTable: any;
}) {
  const [title, setTitle] = useState("");
  const [cover, setCover] = useState("");
  const [content, setContent] = useState("");
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [authToken, setAuthToken] = useState("") as any;
  const uploadImg = useRef<HTMLInputElement>(null);

  const postingDate = new Date();
  let day = postingDate.getDate();
  let month = postingDate.getMonth() + 1;
  let year = postingDate.getFullYear();
  let date = `${year}/${month}/${day}`;


  useEffect(() => {
    const currentUserToken: any = sessionStorage.getItem("authToken");
    setAuthToken(currentUserToken);
  }, []);

  const handleImageUpload = async (e: any) => {
    const imgSize = (e.target.files[0].size)/1000;
    if (imgSize >= 500) {
      e.target.value = null;
      alert("Image size should be less than 500KB..!!");
    } else {
      const file = e.target.files[0];
      const userUploadImage: any = await convertBase64(file);
      setCover(userUploadImage);
    }
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const postBlog = async () => {
    if (title === "") {
      toast.error("Please enter title..!");
    } else if (content === "") {
      toast.error("Please enter content..!");
    } else if (cover === "") {
      toast.error("Please select a blog cover image..!");
    } else {
      setLoadingSpinner(true);
      const config: any = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_LOCAL_BACKEND}api/blogs`,
          {
            title,
            cover,
            content,
            date,
          },
          config
        );
        console.log(response.data);
        setTitle("");
        setContent("");
        setCover("");
        setLoadingSpinner(false);
        toast.success("Blog added successfully");
        props.setNewArticleSection(false);
        props.setAllArticleSection(true);
        props.refreshBlogTable();
      } catch (error: any) {
        toast.error(error);
        setLoadingSpinner(false);
      }
    }
    setLoadingSpinner(false);
  };

  return (
    <React.Fragment>
      <div>
        <h1 className="mt-16 ml-5 text-4xl text-gray-900 font-bold">
          New Blog Article
        </h1>
        <button
          className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-10 mt-8 mb-5"
          onClick={() => {
            props.setAllArticleSection(true);
            props.setEditArticleSection(false);
            props.setNewArticleSection(false);
          }}
        >
          <i className="fa-solid fa-arrow-left"></i> Go back
        </button>
        <div className="container md:w-full">
          <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 m-4">
            <div className="flex flex-col">
              <label className="text-md font-semibold text-indigo-900 mb-2">
                Blog Title
              </label>
              <input
                className="p-3 font-semibold text-md text-slate-700 rounded-lg mb-6 bg-slate-100 focus:outline focus:outline-offset-2 focus:outline-4 outline-indigo-700"
                type="text"
                placeholder="Enter article title"
                value={title}
                onChange={(e: any) => setTitle(e.target.value)}
                autoComplete=""
              />
            </div>
            <div className="flex flex-col">
              <label className="text-md font-semibold text-indigo-900 mb-2">
                Blog Cover
              </label>
              <input
                className="p-3 font-semibold text-md text-slate-700 rounded-lg mb-6 focus:outline focus:outline-offset-2 focus:outline-4 outline-indigo-700 bg-slate-100"
                type="file"
                multiple={false}
                onChange={(e: any) => handleImageUpload(e)}
                ref={uploadImg}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-md font-semibold text-indigo-900 mb-2">
                Blog Content
              </label>
              <textarea
                className="p-3 font-semibold text-md text-slate-700 rounded-lg mb-6 focus:outline focus:outline-offset-2 focus:outline-4 outline-indigo-700 bg-slate-100"
                placeholder="Enter article content"
                rows={15}
                value={content}
                onChange={(e: any) => setContent(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-between md:pl-2 md:pr-2">
              <button
                className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={postBlog}
              >
                Post Article <i className="fa-solid fa-rocket"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
      {loadingSpinner ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
