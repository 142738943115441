import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../components/LoadingSpinner";
import axios from "axios";
import { VIEWS } from "../../utils/Views";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useScrollToTop } from "../../hooks/useScrollToTop";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [searchParams] = useSearchParams();
  const resetPasswordLink = searchParams.get("token");

  useEffect(() => {
    if(!resetPasswordLink){
      navigate(VIEWS.ADMINSIGNIN);
    }
  },[])

  useDocumentTitle("Reset Password | Dr. Sugi Mental Health");
  useScrollToTop();

  const resetPassword = async () => {
    if (newPassword === "") {
      toast.error("Please enter new password..!!");
    } else if (confirmPassword === "") {
      toast.error("Please enter confirm password..!!");
    } else if (newPassword !== confirmPassword) {
      toast.error("New password & confirm password is not match..!!");
    }else {
      setLoadingSpinner(true);
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_LOCAL_BACKEND}api/user/resetPassword`,
          {
            newPassword,
            confirmPassword,
            resetPasswordLink
          }
        );
        setNewPassword("");
        setConfirmPassword("");
        navigate(VIEWS.ADMINSIGNIN);
      } catch (error: any) {
        setLoadingSpinner(false);
        toast.error(error.message);
      }
    }
    setLoadingSpinner(false);
  };

  return (
    <React.Fragment>
            <div className="w-screen min-h-screen bg-indigo-900">
        <div className="flex mb-4 justify-center items-center">
          <div className="container md:w-1/2">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 m-4 mt-40">
              <h1 className="mt-3 mb-10 text-4xl text-gray-900 font-bold">
                <i className="fa-solid fa-screwdriver-wrench"></i> Reset Password
              </h1>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  New Password
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e: any) => setNewPassword(e.target.value)}
                  autoComplete=""
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Confirm Password
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e: any) => setConfirmPassword(e.target.value)}
                  autoComplete=""
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={resetPassword}
                >
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {loadingSpinner ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
