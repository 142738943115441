import React from "react";
import Footer from "../components/Footer";
import ImageGallerySection from "../components/ImageGallerySection";
import TopNavigation from "../components/TopNavigation";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { useScrollToTop } from "../hooks/useScrollToTop";

export default function Gallery() {
  useDocumentTitle("Gallery | Dr. Sugi Mental Health");
  useScrollToTop();
  return (
    <React.Fragment>
      <TopNavigation activeItem={"GALLERY"} />
      <div className="mt-24">
        <ImageGallerySection />
        <Footer />
      </div>
    </React.Fragment>
  );
}
