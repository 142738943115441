import React from "react";

export default function Footer() {
  return (
    <React.Fragment>
      <div className="w-full bg-indigo-900 h-fit p-5 flex justify-center items-center flex-col">
        <h1 className="font-sans text-white font-bold mt-10 mb-5 uppercase text-3xl">
          Connect with me
        </h1>
        <div 
          className="flex flex-row" 
          // data-aos="zoom-in"
        >
          <a
            href="https://www.linkedin.com/in/dr-sugi-sugandika-licensed-psychotherapist-usa-dsl-lmhc-ny-lpc-nj-master-casac-clssbb-68664215/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-4xl hover:text-gray-300 p-4"
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              role="img"
              viewBox="0 0 24 24"
              fontSize="large"
              height="2em"
              width="auto"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
            </svg>
          </a>
          <a
            href="https://www.ted.com/profiles/22596937"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-4xl hover:text-gray-300 p-4"
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              role="img"
              viewBox="0 0 24 24"
              fontSize="large"
              height="2.5em"
              width="auto"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 7.664v2.223h2.43v6.449H5.1v-6.45h2.43V7.665zm7.945 0v8.672h7.31v-2.223h-4.638v-1.08h4.637v-2.066h-4.637v-1.08h4.637V7.664zm7.759 0v8.672h3.863c3.024 0 4.433-1.688 4.433-4.349 0-2.185-1.021-4.323-3.912-4.323zm2.672 2.223h.85c1.931 0 2.102 1.518 2.102 2.063 0 .815-.243 2.163-1.907 2.163h-1.045z" />
            </svg>
          </a>
          <a
            href="https://www.facebook.com/people/Dr-Sugi-Mental-Health-Dr-%E0%B7%83%E0%B7%94%E0%B6%9C%E0%B7%93%E0%B6%9C%E0%B7%99%E0%B6%B1%E0%B7%8A-%E0%B7%83%E0%B7%94%E0%B7%80%E0%B6%B4%E0%B6%AD%E0%B7%8A-%E0%B6%B8%E0%B6%B1%E0%B7%83%E0%B6%9A%E0%B6%A7-%E0%B6%B8%E0%B6%9F/100063918914312/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-4xl hover:text-gray-300 p-4"
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              role="img"
              viewBox="0 0 24 24"
              fontSize="large"
              height="2em"
              width="auto"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
            </svg>
          </a>
          {/* <a
            href="https://www.facebook.com/ssubawickrama"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-4xl hover:text-gray-300 p-4"
          >
            <i className="fa-brands fa-facebook"></i>
          </a> */}
          {/* <a
            href="https://www.youtube.com/channel/UCrOa87Egkoj6qJeV66Ow9XQ"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-4xl hover:text-gray-300 p-4"
          >
            <i className="fa-brands fa-youtube"></i>
          </a> */}
          {/* <a
            href="https://www.tiktok.com/@drsugiandmentalhealth"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-4xl hover:text-gray-300 p-4"
          >
            <i className="fa-brands fa-tiktok"></i>
          </a> */}
        </div>
        <h6 className="font-sans text-gray-300 font-semibold mt-10 mb-5">
          © 2022 by Dr. Sugi Subawickrama.
        </h6>
        <p className="text-sm text-indigo-500 mb-5">
          <i className="fa-solid fa-code"></i> Developed By
          <a
            href="https://www.linkedin.com/in/samithawijesekara/"
            className="text-indigo-400 font- hover:text-indigo-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Samitha Wijesekara
          </a>
        </p>
      </div>
    </React.Fragment>
  );
}
