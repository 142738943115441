import React from "react";

export default function AdminTopNavBar() {
  return (
    <React.Fragment>
      <React.Fragment>
        <div className="fixed w-full flex items-center justify-between h-20 text-white z-10 bg-indigo-900">
          <div className="flex items-center justify-start md:justify-left pl-5 w-14 md:w-64 h-14 border-none">
            <i className="fa-solid fa-screwdriver-wrench text-3xl"></i>
            <span className="hidden md:block font-bold pl-2 text-xl">
              Admin Dashboard
            </span>
          </div>
          <div className="flex justify-between items-center h-20 header-right"></div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
}
