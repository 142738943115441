import React from "react";
import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";
import TopNavigation from "../components/TopNavigation";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { useScrollToTop } from "../hooks/useScrollToTop";

export default function Contact() {
  useDocumentTitle("Contact | Dr. Sugi Mental Health");
  useScrollToTop();
  return (
    <React.Fragment>
      <TopNavigation activeItem={"CONTACT"} />
      <div className="mt-24">
        <ContactSection />
        <Footer />
      </div>
    </React.Fragment>
  );
}
