import React from "react";
import { Link } from "react-router-dom";
import TopNavigation from "../components/TopNavigation";
import { VIEWS } from "../utils/Views";
import aboutMeImg from "../assets/images/aboutme.jpg";
import headerPropic from "../assets/images/cropped_quality.png";
import Footer from "../components/Footer";
import { useDocumentTitle } from "../hooks/useDocumentTitle";

export default function PageNotFound404() {
  useDocumentTitle("404 Page Not Found | Dr. Sugi Mental Health");
  return (
    <React.Fragment>
      <TopNavigation activeItem={""} />
      <div className="mt-24">
        <div className="p-20 lg:p-24">
          <h1 className="text-3xl lg:text-4xl font-semibold text-slate-400 text-center">
            Oopz.. Page not found..!!
          </h1>
          <h1 className="text-8xl lg:text-9xl font-bold text-indigo-900 text-center mt-5 mb-10 lg:mb-0">
            404
          </h1>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
