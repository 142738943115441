import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../components/LoadingSpinner";
import axios from "axios";

export default function BookingSection() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [date, setDate] = useState("");
  const [speechType, setSpeechType] = useState("");
  const [speechCategory, setSpeechCategory] = useState("");
  const [audienceType, setAudienceType] = useState("");
  const [message, setMessage] = useState("");
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const checkEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const sendBookingAPICall = async () => {
    if (name === "") {
      toast.error("Please enter name..!!");
    } else if (email === "") {
      toast.error("Please enter email..!!");
    } else if (!checkEmail.test(email)) {
      toast.error("Please enter valid email..!!");
    } else if (phoneNumber === "") {
      toast.error("Please enter phone..!!");
    } else if (date === "") {
      toast.error("Please enter date..!!");
    } else if (speechType === "") {
      toast.error("Please enter speech type..!!");
    } else if (audienceType === "") {
      toast.error("Please enter audience type..!!");
    } else if (speechCategory === "") {
      toast.error("Please enter speech category..!!");
    } else if (message === "") {
      toast.error("Please enter message..!!");
    } else {
      setLoadingSpinner(true);

      try {
        await axios.post(`${process.env.REACT_APP_LOCAL_BACKEND}api/bookings`, {
          name,
          email,
          phoneNumber,
          date,
          speechType,
          speechCategory,
          audienceType,
          message,
        });
        setName("");
        setEmail("");
        setPhoneNumber("");
        setDate("");
        setSpeechType("");
        setSpeechCategory("");
        setAudienceType("");
        setMessage("");
        setLoadingSpinner(false);
        toast.success("Booking send successfully..!");
      } catch (error: any) {
        setLoadingSpinner(false);
        toast.error(error.message);
      }
    }
    setLoadingSpinner(false);
  };

  return (
    <React.Fragment>
      <div className="w-full h-fit p-0 md:p-20 grid md:grid-cols-2 gap-0 animate__animated animate__slow animate__fadeIn">
        <div
          className="w-full h-full bg-indigo-700 p-5 lg:p-10 shadow-lg hidden md:block"
          style={{
            backgroundImage:
              "url(https://conference.almamater.si/wp-content/uploads/2022/09/conference_OG_1.jpg)",
            backgroundPosition: "left",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          // data-aos="fade"
        ></div>
        <div
          className="w-full h-full bg-indigo-100 p-5 shadow-lg"
          // data-aos="fade"
        >
          <h1 className="text-center text-6xl lg:text-7xl font-bold uppercase mt-10">
            Booking
          </h1>
          <div className="flex flex-col w-full p-5 lg:p-10">
            <div className="flex flex-col">
              <label className="text-md font-semibold text-indigo-900 mb-2">
                Enter Your Name *
              </label>
              <input
                className="p-3 font-semibold text-md text-indigo-700 rounded-lg mb-6 focus:outline focus:outline-offset-2 focus:outline-4 outline-indigo-700"
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e: any) => setName(e.target.value)}
                autoComplete=""
              />
            </div>
            <div className="flex flex-col">
              <label className="text-md font-semibold text-indigo-900 mb-2">
                Enter Your Email *
              </label>
              <input
                className="p-3 font-semibold text-md text-indigo-700 rounded-lg mb-6 focus:outline focus:outline-offset-2 focus:outline-4 outline-indigo-700"
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                autoComplete=""
              />
            </div>
            <div className="flex flex-col">
              <label className="text-md font-semibold text-indigo-900 mb-2">
                Enter your phone number*
              </label>
              <input
                className="p-3 font-semibold text-md text-indigo-700 rounded-lg mb-6 focus:outline focus:outline-offset-2 focus:outline-4 outline-indigo-700"
                type="number"
                placeholder="Phone number"
                value={phoneNumber}
                onChange={(e: any) => setPhoneNumber(e.target.value)}
                autoComplete=""
              />
            </div>
            <div className="flex flex-col">
              <label className="text-md font-semibold text-indigo-900 mb-2">
                Select the date *
              </label>
              <input
                className="p-3 font-semibold text-md text-indigo-700 rounded-lg mb-6 focus:outline focus:outline-offset-2 focus:outline-4 outline-indigo-700"
                type="date"
                placeholder="Date"
                value={date}
                onChange={(e: any) => setDate(e.target.value)}
                autoComplete=""
              />
            </div>
            <div className="flex flex-col">
              <label className="text-md font-semibold text-indigo-900 mb-2">
                Enter Speech Type *
              </label>
              <input
                className="p-3 font-semibold text-md text-indigo-700 rounded-lg mb-6 focus:outline focus:outline-offset-2 focus:outline-4 outline-indigo-700"
                type="text"
                placeholder="Online, Physical, Zoom etc"
                value={speechType}
                onChange={(e: any) => setSpeechType(e.target.value)}
                autoComplete=""
              />
            </div>
            <div className="flex flex-col">
              <label className="text-md font-semibold text-indigo-900 mb-2">
                Select the speech category *
              </label>
              <select
                value={speechCategory}
                onChange={(e: any) => setSpeechCategory(e.target.value)}
                className="p-3 font-semibold text-md text-indigo-700 rounded-lg mb-6 focus:outline focus:outline-offset-2 focus:outline-4 outline-indigo-700"
              >
                <option value="" defaultValue={"select option"} disabled>
                  Select option
                </option>
                <option value="Strategic Leadership">
                  Strategic Leadership
                </option>
                <option value="Diversity, Inclusion">
                  Diversity, Inclusion
                </option>
                <option value="Culture">Culture</option>
                <option value="Team Building">Team Building</option>
                <option value="Emotional Intelligence">
                  Emotional Intelligence
                </option>
                <option value="Work-Life Balance">Work-Life Balance</option>
                <option value="Soft-Skills">Soft-Skills</option>
                <option value="Mental Health">Mental Health</option>
                <option value="Resilience And Mental Health">
                  Resilience And Mental Health
                </option>
                <option value="Motivational Speaker">
                  Motivational Speaker
                </option>
                <option value="Social Media And Self-Identity">
                  Social Media And Self-Identity
                </option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label className="text-md font-semibold text-indigo-900 mb-2">
                Enter Audience Type *
              </label>
              <input
                className="p-3 font-semibold text-md text-indigo-700 rounded-lg mb-6 focus:outline focus:outline-offset-2 focus:outline-4 outline-indigo-700"
                type="text"
                placeholder="Audience type"
                value={audienceType}
                onChange={(e: any) => setAudienceType(e.target.value)}
                autoComplete=""
              />
            </div>
            <div className="flex flex-col">
              <label className="text-md font-semibold text-indigo-900 mb-2">
                Enter Your Message *
              </label>
              <textarea
                className="p-3 font-semibold text-md text-indigo-700 rounded-lg mb-6 focus:outline focus:outline-offset-2 focus:outline-4 outline-indigo-700"
                placeholder="Message"
                rows={5}
                value={message}
                onChange={(e: any) => setMessage(e.target.value)}
              />
            </div>
            <button
              className="bg-indigo-700 hover:bg-indigo-900 text-white font-bold p-3 rounded-lg mb-10"
              onClick={sendBookingAPICall}
            >
              Confirm Booking
            </button>
          </div>
        </div>
      </div>
      {loadingSpinner ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
