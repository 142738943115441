import React, { useEffect, useState } from "react";

export default function TopicsSpeak() {
  const topicsData = [
    { title: "Mental Health", icon: "fa-solid fa-user-doctor" },
    { title: "Resilience and mental health", icon: "fa-solid fa-flag" },
    { title: "Motivational speaker", icon: "fa-solid fa-person-hiking" },
    { title: "Strategic leadership", icon: "fa-solid fa-flag" },
    { title: "Diversity, Inclusion", icon: "fa-solid fa-person" },
    { title: "Culture", icon: "fa-solid fa-person-praying" },
    { title: "Team Building", icon: "fa-solid fa-people-group" },
    { title: "Emotional Intelligence", icon: "fa-regular fa-face-smile" },
    { title: "Work-life balance", icon: "fa-solid fa-briefcase" },
    { title: "Soft-skills", icon: "fa-solid fa-star-of-life" },
    { title: "Social media and self-identity", icon: "fa-solid fa-hashtag" },
  ];

  return (
    <React.Fragment>
      <div className="w-full p-5 mb-5 md:p-10 md:mb-10 lg:p-20 lg:mb-20">
        <h1 className="text-5xl text-center md:text-left md:text-7xl font-bold uppercase">Topics I Speak</h1>
        <div className="grid grid-col-1 gap-2 md:grid-cols-3 lg:grid-cols-6 md:gap-4 place-items-center h-fit mt-5 md:mt-10">
          {topicsData.map((item: any, index: any) => (
            <div className="flex flex-col justify-center items-center bg-indigo-100 hover:bg-indigo-200 w-full h-48 rounded-2xl p-5" key={index.toString()} 
            // data-aos="zoom-in"
            >
              <i className={`${item.icon} text-4xl text-indigo-900`}></i>
              <h1 className="font-bold capitalize text-md text-center text-indigo-900">
                {item.title}
              </h1>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}
