import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../LoadingSpinner";
import FormInputItem from "./FormInputItem";
import axios from "axios";
import PopUp from "../PopUp";

export default function AdminVideos() {
  const [url, setURL] = useState("");
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [allEngVideosData, setAllEngVideosData] = useState([]) as any[];
  const [allSinVideosData, setAllSinVideosData] = useState([]) as any[];
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [authToken, setAuthToken] = useState("") as any;
  const [popUpConfirm, setPopUpConfirm] = useState({
    open: false,
    title: "",
    content: "",
    itemID: "",
  });

  useEffect(() => {
    const currentUserToken: any = sessionStorage.getItem("authToken");
    setAuthToken(currentUserToken);
    getAllEngVideos();
    getAllSinVideos();
  }, []);

  const getAllEngVideos = async () => {
    setLoadingSpinner(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_BACKEND}api/videos/english`
      );
      console.log(response.data);
      setAllEngVideosData(response.data);
      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
      toast.error(error.message);
    }
    setLoadingSpinner(false);
  };

  const getAllSinVideos = async () => {
    setLoadingSpinner(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_BACKEND}api/videos/sinhala`
      );
      console.log(response.data);
      setAllSinVideosData(response.data);
      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
      toast.error(error.message);
    }
    setLoadingSpinner(false);
  };

  const postVideo = async () => {
    if (url === "") {
      toast.error("Please enter url..!!");
    } else if (title === "") {
      toast.error("Please enter title..!!");
    } else if (type === "") {
      toast.error("Please select video type..!!");
    } else {
      setLoadingSpinner(true);
      const config: any = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };
      try {
        await axios.post(
          `${process.env.REACT_APP_LOCAL_BACKEND}api/videos`,
          {
            url,
            title,
            type,
          },
          config
        );
        setURL("");
        setTitle("");
        setType("");
        getAllEngVideos();
        getAllSinVideos();
        setLoadingSpinner(false);
      } catch (error: any) {
        setLoadingSpinner(false);
        toast.error(error.message);
      }
      setLoadingSpinner(false);
    }
  };

  const deleteVideoPopUP = (blogID: any) => {
    setPopUpConfirm({
      title: "Alert",
      content: "Are you sure want to delete this video?",
      open: true,
      itemID: blogID,
    });
  };

  const deleteVideoConfirm = () => {
    deleteVideo(popUpConfirm.itemID);
    setPopUpConfirm({ ...popUpConfirm, open: false });
  };

  const deleteVideo = async (videoID: any) => {
    setLoadingSpinner(true);
    const config: any = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    try {
      await axios.delete(
        `${process.env.REACT_APP_LOCAL_BACKEND}api/videos/${videoID}`,
        config
      );
      getAllEngVideos();
      getAllSinVideos();
      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
      toast.error(error.message);
    }
    setLoadingSpinner(false);
  };

  return (
    <React.Fragment>
      <h1 className="mt-16 ml-5 text-4xl text-gray-900 font-bold">
        Video Gallery
      </h1>
      <h6 className="mt-10 ml-10 text-md text-gray-900 font-bold capitalize">
        Add YouTube videos
      </h6>
      <div className="container md:w-full">
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 m-4">
          <FormInputItem
            label="Youtube video URL"
            type="text"
            placeHolder="Ex:- https://youtu.be/MBeAtVIj45Y"
            value={url}
            onChange={setURL}
          />
          <FormInputItem
            label="Title"
            type="text"
            placeHolder="Enter Title"
            value={title}
            onChange={setTitle}
          />
          <div className="mb-4 md:w-1/2 md:pl-2 md:pr-2">
            <label className="text-md font-semibold text-indigo-900 mb-2">
              Select the video type *
            </label>
            <select
              value={type}
              onChange={(e: any) => setType(e.target.value)}
              className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="" defaultValue={"select option"} disabled>
                Select option
              </option>
              <option value="English">English</option>
              <option value="Sinhala">Sinhala</option>
            </select>
          </div>
          <div className="flex items-center justify-between md:pl-2 md:pr-2">
            <button
              className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={postVideo}
            >
              Add Video
            </button>
          </div>
        </form>
        <h6 className="mt-10 ml-10 text-md text-gray-900 font-bold capitalize">
          English YouTube videos
        </h6>
        {allEngVideosData.length === 0 ? (
          <p className="text-sm tracking-wide text-gray-500 bg-gray-100 text-left w-96 rounded-xl p-5 m-4">
            You have no videos.
          </p>
        ) : null}
        <div className="md:w-full h-fit p-10 grid grid-cols-2 gap-0 lg:grid-cols-3 lg:gap-4">
          {allEngVideosData
            .map((item: any, index: any) => (
              <div
                className="flex flex-col justify-center item-center h-36 rounded-xl m-4 mt-10"
                key={index.toString()}
              >
                <iframe
                  src={
                    "https://www.youtube.com/embed/" + item.url.split("/")[3]
                  }
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="rounded-xl"
                ></iframe>
                <div className="w-full flex justify-between items-center">
                  <h1 className="font-semibold text-md text-black">
                    {item.title}
                  </h1>
                  <button
                    className="bg-black bg-opacity-40 hover:bg-red-500 hover:bg-opacity-80 text-white text-center text-sm py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline mt-2 mb-5"
                    type="button"
                    onClick={() => deleteVideoPopUP(item._id)}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                </div>
              </div>
            ))
            .reverse()}
        </div>
        <h6 className="mt-10 ml-10 text-md text-gray-900 font-bold capitalize">
          Sinhala YouTube videos
        </h6>
        {allSinVideosData.length === 0 ? (
          <p className="text-sm tracking-wide text-gray-500 bg-gray-100 text-left w-96 rounded-xl p-5 m-4">
            You have no videos.
          </p>
        ) : null}
        <div className="md:w-full h-fit p-10 grid grid-cols-2 gap-0 lg:grid-cols-3 lg:gap-4">
          {allSinVideosData
            .map((item: any, index: any) => (
              <div
                className="flex flex-col justify-center item-center h-36 rounded-xl m-4 mt-10"
                key={index.toString()}
              >
                <iframe
                  src={
                    "https://www.youtube.com/embed/" + item.url.split("/")[3]
                  }
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="rounded-xl"
                ></iframe>
                <div className="w-full flex justify-between items-center">
                  <h1 className="font-semibold text-sm text-black mt-2">
                    {item.title}
                  </h1>
                  <button
                    className="bg-black bg-opacity-40 hover:bg-red-500 hover:bg-opacity-80 text-white text-center text-sm py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline mt-2 mb-5"
                    type="button"
                    onClick={() => deleteVideoPopUP(item._id)}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                </div>
              </div>
            ))
            .reverse()}
        </div>
      </div>
      {loadingSpinner ? <LoadingSpinner /> : null}
      <ToastContainer />
      <PopUp
        popUpDisplay={popUpConfirm.open}
        handleClose={() => setPopUpConfirm({ ...popUpConfirm, open: false })}
        handleOpen={() => setPopUpConfirm({ ...popUpConfirm, open: true })}
        popUpContent={popUpConfirm.content}
        handleConfirm={deleteVideoConfirm}
      />
    </React.Fragment>
  );
}
