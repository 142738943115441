export enum VIEWS {
    HOME = '/',
    ABOUT = '/about',
    GALLERY = '/gallery',
    BOOKING = '/booking',
    BLOG = '/blog',
    ARTICLE = '/blog/article',
    CONTACT = '/contact',
    ADMINSIGNIN = '/admin',
    ADMINFORGOTPASSWORD = '/admin/forgotPassword',
    ADMINRESETPASSWORD = '/admin/resetpassword',
    ADMINDASHBOARD = 'dashboard',
    PAGENOTFOUND404 = '*'
}