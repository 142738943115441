import React from "react";
import BookingSection from "../components/BookingSection";
import Footer from "../components/Footer";
import TopNavigation from "../components/TopNavigation";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { useScrollToTop } from "../hooks/useScrollToTop";

export default function Booking() {
  useDocumentTitle("Booking | Dr. Sugi Mental Health");
  useScrollToTop();
  return (
    <React.Fragment>
      <TopNavigation activeItem={"BOOKING"} />
      <div className="mt-24">
        <BookingSection />
        <Footer />
      </div>
    </React.Fragment>
  );
}
