import React from 'react'

export default function PopUpFullSizeImage(props:{
    fullSizeImageDisplay: boolean;
    handleClose: any;
    handleOpen: any;
    imageUrl: string;
}) {
  return (
    <React.Fragment>
        {props.fullSizeImageDisplay? (
            <div className="w-full h-full bg-black bg-opacity-95 flex items-center justify-center top-0 left-0 fixed z-50">
                <button className='flex items-center justify-center rounded-full bg-slate-300 text-black absolute top-5 right-5 p-5 w-10 h-10 text-xl bg-opacity-60 hover:bg-indigo-400 hover:text-white' onClick={props.handleClose}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
                <img src={props.imageUrl} alt="" className='w-auto h-auto md:h-screen p-10'/>
            </div>
        ):null}
    </React.Fragment>
  )
}
