import React from "react";

export default function PopUp(props: {
  popUpDisplay: boolean;
  handleClose: any;
  handleOpen: any;
  popUpContent: string;
  handleConfirm: any;
  confirmBtnText?: any;
}) {
  return (
    <React.Fragment>
      {props.popUpDisplay ? (
        <div onClick={props.handleClose} className="w-full h-full bg-black bg-opacity-50 flex items-center justify-center top-0 left-0 fixed z-50">
          <div className="w-1/3 bg-white rounded-xl h-64 text-center z-50">
            <h1 className="font-bold mt-14 mb-10 text-3xl">Alert</h1>
            <p className="font-semibold mt-5 mb-5 text-md">
              {props.popUpContent}
            </p>
            <div className=" flex flex-row items-center justify-center">
              <button
                className="bg-indigo-700 hover:bg-indigo-600 text-white font-semibold py-2 px-4 rounded"
                onClick={props.handleConfirm}
              >
                Yes
              </button>
              <button
                className="bg-slate-400 hover:bg-slate-500 text-white font-semibold py-2 px-4 rounded ml-1"
                onClick={props.handleClose}
              >
                No
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}
