import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../components/LoadingSpinner";
import axios from "axios";

export default function VideoGalleryEnglish() {
  const [allVideosData, setAllVideosData] = useState([]) as any[];
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  useEffect(() => {
    getAllVideos();
  }, []);

  const getAllVideos = async () => {
    setLoadingSpinner(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_BACKEND}api/videos/english`
      );
      setAllVideosData(response.data);
      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
    }
  };

  return (
    <React.Fragment>
      <div>
        <div
          className="flex flex-col items-center w-full mt-8 lg:mt-16 h-64 md:h-fit pl-3 pr-3 lg:pl-48 lg:pr-48"
          // data-aos="fade-down"
        >
          <iframe
            className="w-full h-96"
            src={"https://www.youtube.com/embed/YDtWuQjPptQ"}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8 lg:mt-16 pb-16">
          {allVideosData
            .slice(0, 6)
            .map((item: any, index: any) => (
              <div
                className="bg-indigo-600 h-56 rounded-lg"
                key={index.toString()}
              >
                <iframe
                  className="w-full h-full rounded-lg"
                  src={
                    "https://www.youtube.com/embed/" + item.url.split("/")[3]
                  }
                  frameBorder="0"
                  allow="accelerometer"
                  allowFullScreen
                ></iframe>
                <h1 className="font-semibold text-md text-black mt-3">
                  {item.title}
                </h1>
              </div>
            ))
            .reverse()}
        </div>
      </div>
      {loadingSpinner ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
