import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../LoadingSpinner";
import axios from "axios";
import PopUp from "../PopUp";

export default function AdminMessages() {
  const [allMessages, setAllMessages] = useState([]) as any[];
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [authToken, setAuthToken] = useState("") as any;
  const [popUpConfirm, setPopUpConfirm] = useState({
    open: false,
    title: "",
    content: "",
    itemID: "",
  });

  useEffect(() => {
    const currentUserToken: any = sessionStorage.getItem("authToken");
    setAuthToken(currentUserToken);
    getAllMessages(currentUserToken);
  }, []);

  const getAllMessages = async (authToken: any) => {
    setLoadingSpinner(true);
    const config: any = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_BACKEND}api/contacts`,
        config
      );
      console.log(response.data);
      setAllMessages(response.data);
      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
      toast.error(error.message);
    }
    setLoadingSpinner(false);
  };

  const deleteMessagePopUP = (msdID: any) => {
    setPopUpConfirm({
      title: "Alert",
      content: "Are you sure want to delete this message?",
      open: true,
      itemID: msdID,
    });
  };

  const deleteMessageConfirm = () => {
    deleteMessage(popUpConfirm.itemID);
    setPopUpConfirm({ ...popUpConfirm, open: false });
  };

  const deleteMessage = async (msgID: any) => {
    setLoadingSpinner(true);
    const config: any = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    try {
      await axios.delete(
        `${process.env.REACT_APP_LOCAL_BACKEND}api/contacts/${msgID}`,
        config
      );
      getAllMessages(authToken);
      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
      toast.error(error.message);
    }
    setLoadingSpinner(false);
  };

  const marksAsReadMessage = async (msgID: any) => {
    setLoadingSpinner(true);
    const config: any = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    try {
      await axios.put(
        `${process.env.REACT_APP_LOCAL_BACKEND}api/contacts/${msgID}`,
        {
          status: 1,
        },
        config
      );
      getAllMessages(authToken);
      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
      toast.error(error.message);
    }
    setLoadingSpinner(false);
  };

  return (
    <React.Fragment>
      <h1 className="mt-16 ml-5 text-4xl text-gray-900 font-bold">
        All Messages
      </h1>
      <h6 className="mt-10 ml-10 text-md text-gray-900 font-bold capitalize">
        Messages from your contact form
      </h6>
      <div className="mx-4">
        <div className="w-full overflow-hidden rounded-lg shadow-xs mt-5">
          <div className="w-full overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b bg-gray-200 ">
                  <th className="px-4 py-3">Name</th>
                  <th className="px-4 py-3">Email</th>
                  <th className="px-4 py-3">Phone number</th>
                  <th className="px-4 py-3">Message</th>
                  <th className="px-4 py-3">Status</th>
                  <th className="px-4 py-3">Action</th>
                </tr>
                {allMessages.length === 0 ? (
                  <tr className="text-xs tracking-wide text-gray-400 border-b bg-gray-100 text-center">
                    <th className="p-10" colSpan={6}>
                      You have no messages.
                    </th>
                  </tr>
                ) : null}
              </thead>
              <tbody className="bg-white divide-y">
                {allMessages
                  .map((item: any, index: any) => (
                    <tr
                      className="bg-gray-100 hover:bg-gray-300 text-gray-700"
                      key={index.toString()}
                    >
                      <td className="px-4 py-3">
                        <div className="flex items-center text-sm">
                          <div>
                            <p className="font-semibold">{item.name}</p>
                          </div>
                        </div>
                      </td>
                      <td className="px-4 py-3 text-sm">{item.email}</td>
                      <td className="px-4 py-3 text-sm">{item.phoneNumber}</td>
                      <td className="px-4 py-3 text-sm">{item.message}</td>
                      <td className="px-4 py-3 text-sm">
                        {item.status == 0 ? (
                          <span className="px-2 py-1 font-semibold leading-tight text-indigo-700 bg-indigo-100 rounded-full">
                            Unread
                          </span>
                        ) : null}
                        {item.status == 1 ? (
                          <span className="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full">
                            Read
                          </span>
                        ) : null}
                      </td>
                      <td className="px-4 py-3 text-sm">
                        {item.status == 0 ? (
                          <button
                            className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
                            type="button"
                            onClick={() => {
                              marksAsReadMessage(item._id);
                            }}
                          >
                            Mark as Read <i className="fa-solid fa-check"></i>
                          </button>
                        ) : null}
                        <button
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
                          type="button"
                          onClick={() => {
                            deleteMessagePopUP(item._id);
                          }}
                        >
                          Delete <i className="fa-solid fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))
                  .reverse()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {loadingSpinner ? <LoadingSpinner /> : null}
      <ToastContainer />
      <PopUp
        popUpDisplay={popUpConfirm.open}
        handleClose={() => setPopUpConfirm({ ...popUpConfirm, open: false })}
        handleOpen={() => setPopUpConfirm({ ...popUpConfirm, open: true })}
        popUpContent={popUpConfirm.content}
        handleConfirm={deleteMessageConfirm}
      />
    </React.Fragment>
  );
}
