import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../components/LoadingSpinner";
import axios from "axios";

export default function ContactSection() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const checkEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const sendContactAPICall = async () => {
    if (name === "") {
      toast.error("Please enter name..!!");
    } else if (email === "") {
      toast.error("Please enter email..!!");
    } else if (!checkEmail.test(email)) {
      toast.error("Please enter valid email..!!");
    } else if (phoneNumber === "") {
      toast.error("Please enter phone..!!");
    } else if (message === "") {
      toast.error("Please enter message..!!");
    } else {
      setLoadingSpinner(true);

      try {
        await axios.post(`${process.env.REACT_APP_LOCAL_BACKEND}api/contacts`, {
          name,
          email,
          phoneNumber,
          message,
        });
        setName("");
        setEmail("");
        setPhoneNumber("");
        setMessage("");
        setLoadingSpinner(false);
        toast.success("Message send successfully..!");
      } catch (error: any) {
        setLoadingSpinner(false);
        toast.error(error.message);
      }
    }
    setLoadingSpinner(false);
  };

  return (
    <React.Fragment>
      <div className="w-full h-fit p-0 md:p-20 grid md:grid-cols-2 gap-0 animate__animated animate__slow animate__fadeIn">
        <div
          className="w-full h-full bg-indigo-100 p-5 shadow-lg"
          // data-aos="fade"
        >
          <h1 className="text-center text-6xl lg:text-7xl font-bold uppercase mt-10">
            Contact
          </h1>
          <div className="flex flex-col w-full p-5 lg:p-10">
            <div className="flex flex-col">
              <label className="text-md font-semibold text-indigo-900 mb-2">
                Enter Your Name *
              </label>
              <input
                className="p-3 font-semibold text-md text-indigo-700 rounded-lg mb-6 focus:outline focus:outline-offset-2 focus:outline-4 outline-indigo-700"
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e: any) => setName(e.target.value)}
                autoComplete=""
              />
            </div>
            <div className="flex flex-col">
              <label className="text-md font-semibold text-indigo-900 mb-2">
                Enter Your Email *
              </label>
              <input
                className="p-3 font-semibold text-md text-indigo-700 rounded-lg mb-6 focus:outline focus:outline-offset-2 focus:outline-4 outline-indigo-700"
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                autoComplete=""
              />
            </div>
            <div className="flex flex-col">
              <label className="text-md font-semibold text-indigo-900 mb-2">
                Enter Your Phone *
              </label>
              <input
                className="p-3 font-semibold text-md text-indigo-700 rounded-lg mb-6 focus:outline focus:outline-offset-2 focus:outline-4 outline-indigo-700"
                type="number"
                placeholder="Phone"
                value={phoneNumber}
                onChange={(e: any) => setPhoneNumber(e.target.value)}
                autoComplete=""
              />
            </div>
            <div className="flex flex-col">
              <label className="text-md font-semibold text-indigo-900 mb-2">
                Enter Your Message *
              </label>
              <textarea
                className="p-3 font-semibold text-md text-indigo-700 rounded-lg mb-6 focus:outline focus:outline-offset-2 focus:outline-4 outline-indigo-700"
                placeholder="Message"
                rows={5}
                value={message}
                onChange={(e: any) => setMessage(e.target.value)}
              />
            </div>
            <button
              className="bg-indigo-700 hover:bg-indigo-900 text-white font-bold p-3 rounded-lg mb-10"
              onClick={sendContactAPICall}
            >
              Send Message
            </button>
          </div>
        </div>
        <div
          className="w-full h-full bg-indigo-700 p-5 lg:p-10 shadow-lg"
          // data-aos="fade"
        >
          <h1 className="text-4xl md:text-5xl font-bold uppercase mt-10 text-white text-center lg:text-right">
            GET IN TOUCH
          </h1>
          <p className="text-white font-bold text-md lg:text-xl text-left lg:text-right mt-14 leading-loose lg:leading-10">
            Press & Media Inquiries Welcome <br /> Partnership and Sponsorship
            Requests Welcome <br /> Open for Speaking Engagements & Conferences
          </p>
          <div className="flex flex-col">
            <a
              className="text-white font-semibold text-md text-left mt-10 lg:mt-20 leading-10"
              href="mailto:sugisubhawickrama@gmail.com"
            >
              <i className="fa-regular fa-envelope mr-3"></i>
              hello@drsugimentalhealth.com
            </a>
            {/* <a
              className="text-white font-semibold text-md text-left leading-10"
              href="tel:+12064563059"
            >
              <i className="fa-solid fa-phone mr-3"></i>+1 2064 563 059
            </a> */}
            <a
              className="text-white font-semibold text-md text-left leading-10"
              href="https://goo.gl/maps/GxC1Y3sWB5LoUXiAA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-solid fa-location-dot mr-3"></i> United States
            </a>
            <p className="text-white font-semibold text-md text-left leading-10">
              <i className="fa-solid fa-link mr-3"></i>
              https://drsugimentalhealth.com/
            </p>
          </div>
          <div className="hidden md:block">
            <div className="flex flex-row mt-10">
              {/* <a
                href="https://www.youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-4xl hover:text-gray-300 md:p-2 lg:p-4"
              >
                <i className="fa-brands fa-facebook"></i>
              </a> */}
              {/* <a
                href="https://www.youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-4xl hover:text-gray-300 md:p-2 lg:p-4"
              >
                <i className="fa-brands fa-instagram"></i>
              </a> */}
              {/* <a
                href="https://www.youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-4xl hover:text-gray-300 md:p-2 lg:p-4"
              >
                <i className="fa-brands fa-youtube"></i>
              </a> */}
              {/* <a
                href="https://www.youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-4xl hover:text-gray-300 md:p-2 lg:p-4"
              >
                <i className="fa-brands fa-tiktok"></i>
              </a> */}
              <a
                href="https://www.linkedin.com/in/dr-sugi-sugandika-licensed-psychotherapist-usa-dsl-lmhc-ny-lpc-nj-master-casac-clssbb-68664215/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-4xl hover:text-gray-300 md:p-2 lg:p-4"
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  role="img"
                  viewBox="0 0 24 24"
                  fontSize="large"
                  height="2em"
                  width="auto"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                </svg>
              </a>
              <a
                href="https://www.ted.com/profiles/22596937"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-4xl hover:text-gray-300 md:p-2 lg:p-4"
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  role="img"
                  viewBox="0 0 24 24"
                  fontSize="large"
                  height="2.5em"
                  width="auto"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 7.664v2.223h2.43v6.449H5.1v-6.45h2.43V7.665zm7.945 0v8.672h7.31v-2.223h-4.638v-1.08h4.637v-2.066h-4.637v-1.08h4.637V7.664zm7.759 0v8.672h3.863c3.024 0 4.433-1.688 4.433-4.349 0-2.185-1.021-4.323-3.912-4.323zm2.672 2.223h.85c1.931 0 2.102 1.518 2.102 2.063 0 .815-.243 2.163-1.907 2.163h-1.045z" />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/people/Dr-Sugi-Mental-Health-Dr-%E0%B7%83%E0%B7%94%E0%B6%9C%E0%B7%93%E0%B6%9C%E0%B7%99%E0%B6%B1%E0%B7%8A-%E0%B7%83%E0%B7%94%E0%B7%80%E0%B6%B4%E0%B6%AD%E0%B7%8A-%E0%B6%B8%E0%B6%B1%E0%B7%83%E0%B6%9A%E0%B6%A7-%E0%B6%B8%E0%B6%9F/100063918914312/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-4xl hover:text-gray-300 p-4"
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  role="img"
                  viewBox="0 0 24 24"
                  fontSize="large"
                  height="2em"
                  width="auto"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      {loadingSpinner ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
