import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../LoadingSpinner";
import FormInputItem from "./FormInputItem";
import axios from "axios";

export default function AdminSettings() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [password, setPassword] = useState("");
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [authToken, setAuthToken] = useState("") as any;

  useEffect(() => {
    const currentUserToken: any = sessionStorage.getItem("authToken");
    setAuthToken(currentUserToken);
  }, []);

  const changePassword = async () => {
    if (oldPassword === "") {
      toast.error("Please enter old password..!");
    } else if (newPassword === "") {
      toast.error("Please enter new password..!");
    } else if (password === "") {
      toast.error("Please enter confirm password..!");
    } else if (newPassword !== password) {
      toast.error("New password and confirm password is not matching..!!");
    } else {
      setLoadingSpinner(true);
      const config: any = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_LOCAL_BACKEND}api/user/changePassword`,
          {
            oldPassword,
            newPassword,
            password,
          },
          config
        );
        console.log(response.data);
        setOldPassword("");
        setNewPassword("");
        setPassword("");
        sessionStorage.setItem("authToken", response.data.token);
        setLoadingSpinner(false);
        toast.success("Password change successfully");
      } catch (error: any) {
        toast.error(error);
        setLoadingSpinner(false);
      }
    }
    setLoadingSpinner(false);
  };

  return (
    <React.Fragment>
      <h1 className="mt-16 ml-5 text-4xl text-gray-900 font-bold">
        Admin Settings
      </h1>
      <h6 className="mt-10 ml-10 text-md text-gray-900 font-bold capitalize">
        Change admin dashboard password
      </h6>
      <div className="container md:w-full">
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 m-4">
          <FormInputItem
            label="Old Password"
            type="password"
            placeHolder="Old Password"
            value={oldPassword}
            onChange={setOldPassword}
          />
          <FormInputItem
            label="New Password"
            type="password"
            placeHolder="New Password"
            value={newPassword}
            onChange={setNewPassword}
          />
          <FormInputItem
            label="Confirm Password"
            type="password"
            placeHolder="Confirm Password"
            value={password}
            onChange={setPassword}
          />
          <div className="flex items-center justify-between md:pl-2 md:pr-2">
            <button
              className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={changePassword}
            >
              Change Password
            </button>
          </div>
        </form>
      </div>
      {loadingSpinner ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
