import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { VIEWS } from "./utils/Views";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Booking from "./pages/Booking";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import SignIn from "./pages/admin/SignIn";
import Dashboard from "./pages/admin/Dashboard";
import Article from "./pages/Article";
import ResetPassword from "./pages/admin/ResetPassword";
import ForgotPassword from "./pages/admin/ForgotPassword";
import AOS from "aos";
import "aos/dist/aos.css";
import ProtectedRoute from "./utils/ProtectedRoute";
import PageNotFound404 from "./pages/PageNotFound404";
import Gallery from "./pages/Gallery";

function App() {
  useEffect(() => {
    AOS.init({
      delay: 0,
      offset: 0,
      duration: 1000,
      startEvent: "load",
    });
    AOS.refresh();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* ===================== Public Routes ===================== */}
        <Route path={VIEWS.HOME} element={<Home />} />
        <Route path={VIEWS.ABOUT} element={<About />} />
        <Route path={VIEWS.GALLERY} element={<Gallery />} />
        <Route path={VIEWS.BOOKING} element={<Booking />} />
        <Route path={VIEWS.BLOG} element={<Blog />} />
        <Route path={VIEWS.ARTICLE} element={<Article />} />
        <Route path={VIEWS.CONTACT} element={<Contact />} />
        <Route path={VIEWS.ADMINSIGNIN} element={<SignIn />} />
        <Route path={VIEWS.ADMINFORGOTPASSWORD} element={<ForgotPassword />} />
        <Route path={VIEWS.ADMINRESETPASSWORD} element={<ResetPassword />} />
        {/* ===================== Protected Routes ===================== */}
        <Route path="admin" element={<ProtectedRoute />} >
          <Route path={VIEWS.ADMINDASHBOARD} element={<Dashboard />} />
        </Route>
        {/* ===================== 404 Page Not Found ===================== */}
        <Route path={VIEWS.PAGENOTFOUND404} element={<PageNotFound404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
