import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../components/LoadingSpinner";
import axios from "axios";
import { VIEWS } from "../utils/Views";

export default function BlogSection() {
  const navigate = useNavigate();
  const [allBlogs, setAllBlogs] = useState([]) as any[];
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  useEffect(() => {
    getAllBlogArticles();
  }, []);

  const getAllBlogArticles = async () => {
    setLoadingSpinner(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_BACKEND}api/blogs`
      );
      setAllBlogs(response.data || []);
      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
    }
  };

  return (
    <React.Fragment>
      <div className="w-full p-10 mb-36">
        <h1 className="text-5xl md:text-7xl font-bold uppercase">Blog</h1>
        {allBlogs.length === 0 ? (
            <p className="text-xl lg:text-4xl text-gray-400 text-center mt-20">
              {/* Blog articles are loading... */}
            </p>
          ) : null}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-12 gap-x-8 mt-10 lg:mt-20">
          {allBlogs
            .map((item: any, index: any) => (
              <div
                className="w-full bg-indigo-100 hover:bg-indigo-200 h-64 rounded-lg cursor-pointer shadow-xl flex flex-col justify-end items-end animate__animated animate__slow animate__zoomIn"
                key={index.toString()}
                style={{
                  backgroundImage: "url(" + item.cover + ")",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
                // data-aos="zoom-in"
                onClick={() => navigate(`${VIEWS.ARTICLE}?id=${item._id}`)}
              >
                <div className="bg-indigo-900 bg-opacity-80 w-full m-0 p-5 rounded-t-none rounded-lg backdrop-blur-sm">
                  <h1 className="text-md text-white font-semibold">
                    {item.title}
                  </h1>
                  <p className="text-white mt-2">
                    {item.content.substring(0, 40) + "....."}
                  </p>
                </div>
              </div>
            ))
            .reverse()}
        </div>
      </div>
      {loadingSpinner ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
