import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../components/LoadingSpinner";
import axios from "axios";
import { VIEWS } from "../../utils/Views";
import { Link } from "react-router-dom";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useScrollToTop } from "../../hooks/useScrollToTop";

export default function SignIn() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const checkEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  useEffect(()=>{
    const currentUserToken: any = sessionStorage.getItem("authToken");
    if(currentUserToken){
      navigate(VIEWS.ADMINDASHBOARD);
    }
  },[])
  
  useDocumentTitle("Admin Signin | Dr. Sugi Mental Health");
  useScrollToTop();

  const signInToAdminDashboard = async () => {
    if (email === "") {
      toast.error("Please enter email..!!");
    } else if (!checkEmail.test(email)) {
      toast.error("Please enter valid email..!!");
    } else if (password === "") {
      toast.error("Please enter password..!!");
    } else {
      setLoadingSpinner(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_LOCAL_BACKEND}api/user/login`,
          {
            email,
            password,
          }
        );
        sessionStorage.setItem("authToken", response.data.token);
        setEmail("");
        setPassword("");
        navigate(VIEWS.ADMINDASHBOARD);
      } catch (error: any) {
        setLoadingSpinner(false);
        toast.error(error.message);
      }
    }
    setLoadingSpinner(false);
  };

  return (
    <React.Fragment>
      <div className="w-screen min-h-screen bg-indigo-900">
        <div className="flex mb-4 justify-center items-center">
          <div className="container md:w-1/2">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 m-4 mt-40">
              <h1 className="mt-3 mb-10 text-4xl text-gray-900 font-bold">
                <i className="fa-solid fa-screwdriver-wrench"></i> Sign In
              </h1>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Email
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e: any) => setEmail(e.target.value)}
                  autoComplete=""
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Password
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e: any) => setPassword(e.target.value)}
                  autoComplete=""
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={signInToAdminDashboard}
                >
                  Sign In
                </button>
                <Link
                  to={VIEWS.ADMINFORGOTPASSWORD}
                  className="text-indigo-600 hover:text-indigo-800 font-semibold"
                >
                  Forgot Password?
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      {loadingSpinner ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
