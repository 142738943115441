import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../LoadingSpinner";
import FormInputItem from "./FormInputItem";
import axios from "axios";
import PopUp from "../PopUp";

export default function AdminImages() {
  const [imageString, setImageString] = useState("");
  const [allImagesData, setAllImagesData] = useState([]) as any[];
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [authToken, setAuthToken] = useState("") as any;
  const [popUpConfirm, setPopUpConfirm] = useState({
    open: false,
    title: "",
    content: "",
    itemID: "",
  });
  const [url, setUrl] = useState("");
  const uploadImg = useRef<HTMLInputElement>(null);


  useEffect(() => {
    const currentUserToken: any = sessionStorage.getItem("authToken");
    setAuthToken(currentUserToken);
    getAllImages();
  }, []);

  const getAllImages = async () => {
    setLoadingSpinner(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_BACKEND}api/images`
      );
      console.log(response.data);
      setAllImagesData(response.data);
      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
      toast.error(error.message);
    }
    setLoadingSpinner(false);
  };

  const deleteImagePopUP = (blogID: any) => {
    setPopUpConfirm({
      title: "Alert",
      content: "Are you sure want to decline this image?",
      open: true,
      itemID: blogID,
    });
  };

  const deleteImageConfirm = () => {
    deleteImage(popUpConfirm.itemID);
    setPopUpConfirm({ ...popUpConfirm, open: false });
  };

  const deleteImage = async (imgID: any) => {
    setLoadingSpinner(true);
    const config: any = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    try {
      await axios.delete(
        `${process.env.REACT_APP_LOCAL_BACKEND}api/images/${imgID}`,
        config
      );
      getAllImages();
      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
      toast.error(error.message);
    }
    setLoadingSpinner(false);
  };

  const handleImageUpload = async (e: any) => {
    console.log(e.target.files);
    //Check the image size into KB
    const imgSize = (e.target.files[0].size)/1000;
    //Check the image size
    if (imgSize >= 500) {
      e.target.value = null;
      alert("Image size should be less than 500KB..!!");
    } else {
      //Convert image to a base64
      const file = e.target.files[0];
      const userUploadImage: any = await convertBase64(file);
      console.log("Base64 Full : ", userUploadImage);
      setImageString(userUploadImage);
    }
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const postImageFromDevice = async () => {
    setLoadingSpinner(true);
    const config: any = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    try {
      await axios.post(
        `${process.env.REACT_APP_LOCAL_BACKEND}api/images`,
        {
          imageString,
        },
        config
      );
      getAllImages();
      setLoadingSpinner(false);
      toast.success("Image uploaded successfully.")
    } catch (error: any) {
      setLoadingSpinner(false);
      toast.error(error.message);
    }
    setLoadingSpinner(false);
  };

  const postImageFromUrl = async () => {
    setLoadingSpinner(true);
    const config: any = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const imageString = url;
    try {
      await axios.post(
        `${process.env.REACT_APP_LOCAL_BACKEND}api/images`,
        {
          imageString,
        },
        config
      );
      getAllImages();
      setUrl("");
      setLoadingSpinner(false);
      toast.success("Image uploaded successfully.")
    } catch (error: any) {
      setLoadingSpinner(false);
      toast.error(error.message);
    }
    setLoadingSpinner(false);
  };

  return (
    <React.Fragment>
      <h1 className="mt-16 ml-5 text-4xl text-gray-900 font-bold">
        Image Gallery
      </h1>
      <div className="container md:w-full">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 m-4">
          <h6 className="text-md text-gray-900 font-bold capitalize">
            Upload from your device
          </h6>
          <input
            className="shadow appearance-none border rounded w-fit py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="file"
            multiple={false}
            onChange={(e: any) => handleImageUpload(e)}
            ref={uploadImg}
          />
          <button
            className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline m-5"
            onClick={postImageFromDevice}
          >
            Upload Image
          </button>
        </div>
        <h6 className="ml-10 text-md text-gray-900 font-bold capitalize">OR</h6>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 m-4 flex flex-row">
          <FormInputItem
            label="Paste Image URL"
            type="text"
            placeHolder="Ex:- https://cdn.com/429079949232632.jpg"
            value={url}
            onChange={setUrl}
          />
          <button
            className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold h-fit py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-7"
            onClick={postImageFromUrl}
          >
            Upload Image
          </button>
        </div>
        <h6 className="mt-10 ml-10 text-md text-gray-900 font-bold capitalize">
          Manage gallery images
        </h6>
        {allImagesData.length === 0 ? (
          <p className="text-sm tracking-wide text-gray-500 bg-gray-100 text-left w-96 rounded-xl p-5 m-4">
            You have no images.
          </p>
        ) : null}
        <div className="md:w-full h-fit p-10 grid grid-cols-2 gap-0 lg:grid-cols-4 lg:gap-4">
          {allImagesData
            .map((item: any, index: any) => (
              <div
                className="flex justify-center items-center h-56 rounded-xl m-4 drop-shadow-lg"
                key={index.toString()}
                style={{
                  backgroundImage: "url(" + item.imageString + ")",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="w-full h-full bg-black bg-opacity-10 hover:bg-opacity-20 top-0 left-0 fixed rounded-xl"></div>
                <button
                  className="bg-black bg-opacity-30 hover:bg-red-500 hover:bg-opacity-80 text-white text-center text-sm p-4 rounded-full focus:outline-none focus:shadow-outline h-16 w-16 z-50"
                  type="button"
                  onClick={() => deleteImagePopUP(item._id)}
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
              </div>
            ))
            .reverse()}
        </div>
      </div>
      {loadingSpinner ? <LoadingSpinner /> : null}
      <ToastContainer />
      <PopUp
        popUpDisplay={popUpConfirm.open}
        handleClose={() => setPopUpConfirm({ ...popUpConfirm, open: false })}
        handleOpen={() => setPopUpConfirm({ ...popUpConfirm, open: true })}
        popUpContent={popUpConfirm.content}
        handleConfirm={deleteImageConfirm}
      />
    </React.Fragment>
  );
}
